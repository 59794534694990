<template>
  <b-container class="m-0 p-0">
    <b-row v-for="compound in baseComposition" :key="compound.id" class="mb-2">
      <b-col cols="4">
        <h4 class="subtitle-form">{{ compound.symbol }}</h4>
      </b-col>
      <b-col cols="8" class="d-flex align-items-start">
        <NumericInput
          inputRef="input"
          :value="entryData('dose', compound.id)"
          @input="update(compound.id, 'dose', parseFloat($event)); unitRequired = !!$event;"
          :numberOfDigits="1"
          class="form-control-fx mr-2"
        />
        <b-select
          size="sm"
          text-field="unit"
          value-field="id"
          v-model="unit"
          :required="unitRequired"
          :options="inputFormByPtype.compositionUnits"
          @input="
            update(compound.id, 'unit', $event);
            unit = $event;
          "
        ></b-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// import ActivityModule from "@/store/modules/activity";
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      unit: 0,
      base: ["N", "P", "K"],
      unitRequired: false
    };
  },
  async mounted() {
    if (!this.currentEntry.productType) return;

    await Promise.all([this.$store.dispatch("getinputFormByProductType", { id: this.currentEntry.productType })]);

    if (this.baseComposition.length == 0) return;
    let firstCompound = this.baseComposition[0].id;
    this.unit = this.entryData("unit", firstCompound);
  },
  methods: {
    entryData(field, id) {
      return (
        this.currentEntry.composition &&
        this.currentEntry.composition.find(c => c.compound == id) &&
        this.currentEntry.composition.find(c => c.compound == id)[field]
      );
    },
    update(id, field, value) {
      if (!this.currentEditedEntry.composition || this.currentEditedEntry.composition.length == 0) {
        let cleanData = JSON.parse(JSON.stringify(this.currentEntry.composition || []));
        cleanData = cleanData.map(d => {
          return { compound: d.compound, dose: d.dose, unit: d.unit };
        });
        this.$store.dispatch(this.storeEditAction, {
          composition: cleanData || [],
        });
      }

      let composition = JSON.parse(JSON.stringify(this.currentEditedEntry.composition));

      let data = composition.find(c => c.compound == id) || {};
      data["compound"] = id;
      data[field] = value;

      composition = [...composition.filter(c => c.compound != data.compound), data];

      this.$store.dispatch(this.storeEditAction, {
        composition: JSON.parse(JSON.stringify(composition)),
      });
    },
  },
  computed: {
    ...mapGetters({
      currentProduct: "currentProduct",
    }),
    ...mapGetters(["inputFormByPtype"]),
    baseComposition() {
      if (!this.inputFormByPtype.compounds) return [];
      return this.base.map(b => this.inputFormByPtype.compounds.find(c => c.symbol == b)).filter(b => b != undefined);
    },
  },
  components: {
    NumericInput
  }
};
</script>
